import request from '@/utils/request'


// 查询歌曲11个曲调及释范曲列表
export function listTune(query) {
    return request({
        url: '/music/music-tune/list',
        method: 'get',
        params: query
    })
}

// 查询歌曲11个曲调及释范曲分页
export function pageTune(query) {
    return request({
        url: '/music/music-tune/page',
        method: 'get',
        params: query
    })
}

// 查询歌曲11个曲调及释范曲详细
export function getTune(data) {
    return request({
        url: '/music/music-tune/detail',
        method: 'get',
        params: data
    })
}

// 新增歌曲11个曲调及释范曲
export function addTune(data) {
    return request({
        url: '/music/music-tune/add',
        method: 'post',
        data: data
    })
}

// 修改歌曲11个曲调及释范曲
export function updateTune(data) {
    return request({
        url: '/music/music-tune/edit',
        method: 'post',
        data: data
    })
}

// 删除歌曲11个曲调及释范曲
export function delTune(data) {
    return request({
        url: '/music/music-tune/delete',
        method: 'post',
        data: data
    })
}
