<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='A' prop='videoUrl' >
        <div v-if="form.tuneA && form.tuneA != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneA')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneA'></audio>-->
        </div>

        <a-upload v-if="!form.tuneA"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneA",info)'
                  :customRequest='(info) => videoUploadRequest("tuneA",info)' v-model="form.tuneA">
          <img v-if="form.tuneA != null" :src='form.tuneA' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneA && form.tuneA">
          <a-button @click="restVideo('tuneA')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='B' prop='videoUrl' >
        <div v-if="form.tuneB && form.tuneB != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneB')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneB'></audio>-->
        </div>
        <a-upload v-if="!form.tuneB"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneB",info)'
                  :customRequest='(info) => videoUploadRequest("tuneB",info)' v-model="form.tuneB">
          <img v-if="form.tuneB != null" :src='form.tuneB' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneB && form.tuneB">
          <a-button @click="restVideo('tuneB')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='C' prop='videoUrl' >
        <div v-if="form.tuneC && form.tuneC != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneC')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneC'></audio>-->
        </div>
        <a-upload v-if="!form.tuneC"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneC",info)'
                  :customRequest='(info) => videoUploadRequest("tuneC",info)' v-model="form.tuneC">
          <img v-if="form.tuneC != null" :src='form.tuneC' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneC && form.tuneC">
          <a-button @click="restVideo('tuneC')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='D' prop='videoUrl' >
        <div v-if="form.tuneD && form.tuneD != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneD')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneD'></audio>-->
        </div>
        <a-upload v-if="!form.tuneD"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneD",info)'
                  :customRequest='(info) => videoUploadRequest("tuneD",info)' v-model="form.tuneD">
          <img v-if="form.tuneD != null" :src='form.tuneD' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneD && form.tuneD">
          <a-button @click="restVideo('tuneD')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='E' prop='videoUrl' >
        <div v-if="form.tuneE && form.tuneE != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneE')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneE'></audio>-->
        </div>
        <a-upload v-if="!form.tuneE"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneE",info)'
                  :customRequest='(info) => videoUploadRequest("tuneE",info)' v-model="form.tuneE">
          <img v-if="form.tuneE != null" :src='form.tuneE' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneE && form.tuneE">
          <a-button @click="restVideo('tuneE')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='F' prop='videoUrl' >
        <div v-if="form.tuneF && form.tuneF != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneF')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneF'></audio>-->
        </div>
        <a-upload v-if="!form.tuneF"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneF",info)'
                  :customRequest='(info) => videoUploadRequest("tuneF",info)' v-model="form.tuneF">
          <img v-if="form.tuneF != null" :src='form.tuneF' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneF && form.tuneF">
          <a-button @click="restVideo('tuneF')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='G' prop='videoUrl' >
        <div v-if="form.tuneG && form.tuneG != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneG')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneG'></audio>-->
        </div>
        <a-upload v-if="!form.tuneG"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneG",info)'
                  :customRequest='(info) => videoUploadRequest("tuneG",info)' v-model="form.tuneG">
          <img v-if="form.tuneG != null" :src='form.tuneG' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneG && form.tuneG">
          <a-button @click="restVideo('tuneG')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='#A/bB' prop='videoUrl' >
        <div v-if="form.tuneAbb && form.tuneAbb != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneAbb')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneAbb'></audio>-->
        </div>
        <a-upload v-if="!form.tuneAbb"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneAbb",info)'
                  :customRequest='(info) => videoUploadRequest("tuneAbb",info)' v-model="form.tuneAbb">
          <img v-if="form.tuneAbb != null" :src='form.tuneAbb' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneAbb && form.tuneAbb">
          <a-button @click="restVideo('tuneAbb')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='#C/bD' prop='videoUrl' >
        <div v-if="form.tuneCbd && form.tuneCbd != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneCbd')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneCbd'></audio>-->
        </div>
        <a-upload v-if="!form.tuneCbd"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneCbd",info)'
                  :customRequest='(info) => videoUploadRequest("tuneCbd",info)' v-model="form.tuneCbd">
          <img v-if="form.tuneCbd != null" :src='form.tuneCbd' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneCbd && form.tuneCbd">
          <a-button @click="restVideo('tuneCbd')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='bE/#D' prop='videoUrl' >
        <div v-if="form.tuneBed && form.tuneBed != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneBed')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneBed'></audio>-->
        </div>
        <a-upload v-if="!form.tuneBed"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneBed",info)'
                  :customRequest='(info) => videoUploadRequest("tuneBed",info)' v-model="form.tuneBed">
          <img v-if="form.tuneBed != null" :src='form.tuneBed' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneBed && form.tuneBed">
          <a-button @click="restVideo('tuneBed')">重置</a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item label='bG/#F' prop='videoUrl' >
        <div v-if="form.tuneBgf && form.tuneBgf != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneBgf')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneBgf'></audio>-->
        </div>
        <a-upload v-if="!form.tuneBgf"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneBgf",info)'
                  :customRequest='(info) => videoUploadRequest("tuneBgf",info)' v-model="form.tuneBgf">
          <img v-if="form.tuneBgf != null" :src='form.tuneBgf' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneBgf && form.tuneBgf">
          <a-button @click="restVideo('tuneBgf')">重置</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label='bA/#G' >
        <div v-if="form.tuneBag && form.tuneBag != null" style="display: inline-block;position:relative;">
          <img v-if="bgImg != null " :src='bgImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay('tuneBag')" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
<!--          <audio controls :src='form.tuneBag'></audio>-->
        </div>
        <a-upload v-if="!form.tuneBag"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("tuneBag",info)'
                  :customRequest='(info) => videoUploadRequest("tuneBag",info)' v-model="form.tuneBag">
          <img v-if="form.tuneBag != null" :src='form.tuneBag' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.tuneBag && form.tuneBag">
          <a-button @click="restVideo('tuneBag')">重置</a-button>
        </div>
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <VideoPlay ref="videoPlay"></VideoPlay>
  </a-drawer>
</template>

<script>
import { getTune, addTune, updateTune } from '@/api/music/tune'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import VideoPlay from '@/components/VideoPlay.vue'
export default {
  name: 'CreateForm',
  props: {}
  ,
  components: { VideoPlay },

  data() {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      bgImg:null,
      // 表单参数
      form: {
        id: null,
        musicId: null,
        tuneA: null,
        tuneB: null,
        tuneC: null,
        tuneD: null,
        tuneE: null,
        tuneF: null,
        tuneG: null,
        tuneAbb: null,
        tuneCbd: null,
        tuneBed: null,
        tuneBgf: null,
        tuneBag:null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          {
            required: true, message: '歌曲名称不能为空', trigger: 'blur'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        musicId: null,
        tuneA: null,
        tuneB: null,
        tuneC: null,
        tuneD: null,
        tuneE: null,
        tuneF: null,
        tuneG: null,
        tuneAbb: null,
        tuneCbd: null,
        tuneBed: null,
        tuneBgf: null,
        tuneBag:null
      }
      this.bgImg = null;
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '曲调'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.bgImg = row.videoPic;
      this.formType = 2
      const id = row ? row.id : ids
      getTune({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '曲调'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTune(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTune(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },

    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      Log.info('uploadfileInfo', fileInfo);
      // debugger
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          this.form[field] = res[0].url;
        },
      });
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    videoBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      // console.log('uploadfileInfo====前', fileInfo);
      // if (!fileInfo.type.includes('audio/mpeg')) {
      //   this.$message.warning('请上传音频');
      //   return false;
      // }
      if (!fileInfo.type.includes('video')) {
        this.$message.warning('请上传视频');
        return false;
      }
      if(fileInfo.size > 500*1024*1000){
        this.$message.warning("视频大小不能超过500M");
        return;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    videoUploadRequest(field,fileInfo) {
      console.info('uploadfileInfo上传', fileInfo);
      // debugger
      let that = this;
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log('upload', res);
          this.loading = false;
          this.form[field] = res[0].url;
        },
      });
    },

    restVideo(text) {
      // this.form.videoPic = null;
      console.info('this.text', text);
      this.form[text] = null;

      this.loading = false
    },

    videoPlay: function (text) {
      let data = {
        coverImage: this.bgImg,
        videoUrl: this.form[text]
      };
      this.$refs.videoPlay.show(data);
    },
    // 视频鼠标悬浮时的样式
    enter: function ($event) {
      $event.currentTarget.className = "enter";
    },
    // 视频鼠标离开时的样式
    leave: function ($event) {
      $event.currentTarget.className = "leave";
    },

  }
}
</script>
